import axios from "axios";
import { BaseApiConstant, Endpoint, handleError } from "..";
import { BlogListModel, BlogModel } from "../../models";

export class BlogService {
  static fetchBlogs = async ({
    pageIndex = 1,
  }: {
    pageIndex: number;
  }): Promise<BlogListModel> => {
    const url = BaseApiConstant.baseApiUrl + Endpoint.smartCargoBlogs;

    let paramsRequest: Record<string, any> = {};
    paramsRequest["pageIndex"] = pageIndex;

    try {
      const response = await axios.get(url, { params: paramsRequest });
      const data = new BlogListModel().fromJson(response.data);
      return data;
    } catch (error) {
      const errorResponse = new BlogListModel();
      errorResponse.error = handleError(error);
      return errorResponse;
    }
  };

  static fetchBlogById = async (id: string): Promise<BlogModel> => {
    const url = BaseApiConstant.baseApiUrl + Endpoint.smartCargoBlogById(id);

    try {
      const response = await axios.get(url);
      const data = new BlogModel().fromJson(response.data);
      return data;
    } catch (error) {
      const errorResponse = new BlogModel();
      errorResponse.error = handleError(error);
      return errorResponse;
    }
  };
}

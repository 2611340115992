import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { MwaImage } from "../../components";
import { useFetchBlogs } from "../../hooks";
import { blogsState, loadBlogsState } from "../../states";
import { MwaDateTimeUtil, SessionState } from "../../utils";

const BlogsPage = () => {
  const fetchBlogs = useFetchBlogs();
  const blogList = useRecoilValue(blogsState);
  const loadingBlogs = useRecoilValue(loadBlogsState);

  useEffect(() => {
    switch (loadingBlogs) {
      case SessionState.idle:
      case SessionState.error:
        fetchBlogs();
        break;

      default:
        break;
    }
  }, [fetchBlogs, loadingBlogs]);

  const _renderContent = () => {
    switch (loadingBlogs) {
      case SessionState.success:
        return blogList.items.length > 0 ? (
          <div className="container">
            <div className="row gx-4">
              <div className="col-lg-8">
                <div className="row g-4">
                  {blogList.items.slice(0, 3).map((blogInfo, index) => {
                    return (
                      <div
                        key={index}
                        className={`${index === 0 ? "col-12" : "col-lg-6"}`}
                      >
                        <Link
                          to={`/tin-tuc/${blogInfo.id}`}
                          className="text-black"
                        >
                          <div className="d-flex flex-column h-100">
                            <MwaImage
                              src={blogInfo.thumbnail}
                              alt="thumbnail-img"
                              style={{
                                height: "30vh",
                                width: "100%",
                              }}
                              fallbackSrc="/img/logo.png"
                            />
                            <span
                              className="news-title mt-2"
                              style={{ fontSize: "1.1rem" }}
                            >
                              {blogInfo.name}
                            </span>
                            {blogInfo.createdAt && (
                              <span style={{ fontSize: ".8rem" }}>
                                {MwaDateTimeUtil.historyAgo(blogInfo.createdAt)}
                              </span>
                            )}
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row g-4">
                  {blogList.items.slice(3, 7).map((blogInfo, index) => {
                    return (
                      <div key={index} className="col-12">
                        <Link
                          to={`/tin-tuc/${blogInfo.id}`}
                          className="text-black"
                        >
                          <div className="d-flex">
                            <div
                              style={{
                                height: "8vw",
                                width: "10vw",
                              }}
                            >
                              <MwaImage
                                src={blogInfo.thumbnail}
                                alt="thumbnail-img"
                                style={{
                                  height: "inherit",
                                  width: "inherit",
                                }}
                                fallbackSrc="/img/logo.png"
                              />
                            </div>
                            <div className="d-flex flex-column ms-2">
                              <span
                                className="news-title"
                                style={{ fontSize: ".9rem" }}
                              >
                                {blogInfo.name}
                              </span>
                              {blogInfo.createdAt && (
                                <span style={{ fontSize: ".8rem" }}>
                                  {MwaDateTimeUtil.historyAgo(
                                    blogInfo.createdAt
                                  )}
                                </span>
                              )}
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              {blogList.items
                .slice(7, blogList.items.length)
                .map((blogInfo, index) => {
                  return (
                    <div
                      key={index}
                      className="col-xl-2 col-md-3 col-sm-4 col-12"
                    >
                      <Link
                        to={`/tin-tuc/${blogInfo.id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-body"
                      >
                        <div className="d-flex flex-column">
                          <MwaImage
                            src={blogInfo.thumbnail}
                            alt="thumbnail-img"
                            className="img-thumbnail"
                            style={{ height: "20vh" }}
                            fallbackSrc="/img/logo.png"
                          />
                          <div className="p-2">
                            <div className="news-title">{blogInfo.name}</div>
                            {blogInfo.createdAt && (
                              <span
                                className="mt-2"
                                style={{ fontSize: ".8rem" }}
                              >
                                {MwaDateTimeUtil.historyAgo(blogInfo.createdAt)}
                              </span>
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          <h5 className="color-white d-flex justify-content-center py-4">
            Chưa có tin tức
          </h5>
        );

      case SessionState.loading:
        return (
          <div className="container d-flex flex-column justify-content-center">
            <div className="row">
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index} className="col-lg-6 mb-4">
                  <Skeleton height="40vh" />
                </div>
              ))}
            </div>
          </div>
        );

      case SessionState.error:
        return (
          <div className="d-flex justify-content-center py-3">
            <button
              className="btn btn-danger d-flex flex-column justify-content-center align-items-center"
              onClick={fetchBlogs}
            >
              <span>Lỗi tải tin tức: {blogList.error?.message}</span>
              <span> Thử lại</span>
            </button>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div id="blogs-page" className="main">
      {_renderContent()}
    </div>
  );
};

export default BlogsPage;

import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { BlogService } from "../../services";
import { blogsState, loadBlogsState } from "../../states";
import { SessionState } from "../../utils";

export const useFetchBlogs = ({
  pageIndex = 1,
}: { pageIndex?: number } = {}) => {
  const setLoadingBlogs = useSetRecoilState(loadBlogsState);
  const setBlogList = useSetRecoilState(blogsState);
  return useCallback(async () => {
    setLoadingBlogs(SessionState.loading);

    const response = await BlogService.fetchBlogs({ pageIndex: pageIndex });

    if (response.isSuccess) {
      setLoadingBlogs(SessionState.success);
    } else {
      setLoadingBlogs(SessionState.error);
    }
    setBlogList(response);
  }, [pageIndex, setBlogList, setLoadingBlogs]);
};

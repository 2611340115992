const HomePageHeaderComponent = () => {
  return (
    <section className="hero-section background-img">
      <div className="container">
        <div className="row align-items-center justify-content-between g-4">
          <div className="col-md-6">
            <div className="hero-content-left text-white mt-5">
              <p className="lead mb-1">Hệ thống quản lý vận tải toàn diện</p>
              <h1 className="text-white fw-bold">
                TMS Vận Chuyển Hàng Hóa Thông Minh{" "}
              </h1>
              <ul className="list-style-disc ps-4">
                <li>
                  Sứ mệnh số hóa cho nghành logistic dựa trên nền tảng công nghệ
                  tiên tiến và thông minh.
                </li>
                <li>
                  Giải quyết triệt để những khó khăn trong vấn đề quản lý và vận
                  hành vận tải.
                </li>
                <li>
                  Tăng hiệu quả vận hành lên 70%, kiểm soát và cắt giảm tối đa
                  chi phí lên đến 30%.
                </li>
                <li>
                  Chìa khóa để xây dựng quy trình tự động, giới hạn chế độ phụ
                  thuộc vào con người để dễ dàng mở rộng quy mô và tăng trưởng.
                </li>
              </ul>
              <a href="#van-de" className="btn btn-light page-scroll mb-md-5">
                Tìm hiểu ngay
              </a>
            </div>
          </div>
          <div className="col-md-6 mb-md-0 mb-3">
            <div className="ratio ratio-16x9">
              <iframe
                src="https://www.youtube.com/embed/O4nndotpd78"
                title="YouTube video"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageHeaderComponent;

import {
  HomePageBlogsComponent,
  HomePageHeaderComponent,
  HomePagePricePlanComponent,
  HomePageProblemsComponent,
  HomePageScreenshotsComponent,
  HomePageSolutionsComponent,
} from "./components";
import "./home.css";

const HomePage = () => {
  return (
    <div id="home-page" className="main">
      <HomePageHeaderComponent />
      <HomePageProblemsComponent />
      <HomePageSolutionsComponent />
      <HomePageScreenshotsComponent />
      <HomePagePricePlanComponent />
      <HomePageBlogsComponent />
      {/* <ContactUsComponent /> */}
    </div>
  );
};

export default HomePage;

import Snackbar from "awesome-snackbar";
import axios from "axios";
import { FormEvent, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const RegisterFormComponent = ({
  applicationName,
  onRegisteredSuccess,
}: {
  applicationName: string;
  onRegisteredSuccess?: () => void;
}) => {
  const [isRegistering, setRegisterState] = useState(false);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [capVal, setCapVal] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const emailRegex: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const phoneNumberRegex: RegExp = /^(?:\+?84|0)(?:\d{9}|\d{10})$/;

  const _handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!capVal) {
      new Snackbar("Vui lòng xác nhận không phải robot", {
        position: "bottom-center",
        style: {
          container: [["background-color", "red"]],
        },
      });
      return;
    }

    _onRegistered({
      onSuccess: () => {
        // Reset the reCAPTCHA widget
        if (recaptchaRef.current) {
          recaptchaRef.current!.reset();
        }

        setCapVal(null);
      },
    });
  };

  const _onRegistered = async ({ onSuccess }: { onSuccess: () => void }) => {
    if (!fullname) {
      new Snackbar("Vui lòng nhập họ tên", {
        position: "top-center",
        style: {
          container: [["background-color", "red"]],
        },
      });
      return;
    }

    if (email && !emailRegex.test(email)) {
      new Snackbar("Địa chỉ email không hợp lệ", {
        position: "top-center",
        style: {
          container: [["background-color", "red"]],
        },
      });
      return;
    }

    if (!phone) {
      new Snackbar("Vui lòng nhập số điện thoại", {
        position: "bottom-center",
        style: {
          container: [["background-color", "red"]],
        },
      });
      return;
    } else if (!phoneNumberRegex.test(phone)) {
      new Snackbar("Số điện thoại không hợp lệ", {
        position: "top-center",
        style: {
          container: [["background-color", "red"]],
        },
      });
      return;
    }

    setRegisterState(true);

    try {
      const response = await axios.post(
        "https://delivery-system-api.pycom.vn/api/pycom-consultation-registration",
        {
          name: fullname,
          email: email,
          phoneNumber: phone,
          companyName: companyName,
          applicationName: applicationName,
        },
        {
          headers: {
            accept: "application/json",
            "access-code": "d5ee4822-1054-4c34-8bd5-bb8ba21d3629",
          },
        }
      );

      if (response.status === 200) {
        onSuccess();

        new Snackbar(
          `Cám ơn bạn đã đăng ký trải nghiệm dịch vụ của PyCOM.\nChúng tôi sẽ liên
          lạc với bạn trong thời gian sớm nhất`,
          {
            position: "bottom-center",
            timeout: 5000,
            style: {
              container: [["background-color", "#21D375"]],
            },
          }
        );

        onRegisteredSuccess && onRegisteredSuccess();

        setFullname("");
        setEmail("");
        setPhone("");
        setCompanyName("");
        setRegisterState(false);

        if ((window as any).dataLayer) {
          (window as any).dataLayer.push({
            event: "gtm-dataLayer-contact-submit",
          });
        }
        return;
      }

      new Snackbar(`Gửi yêu cầu bị lỗi - HTTP Status Code ${response.status}`, {
        position: "top-center",
        style: {
          container: [["background-color", "red"]],
        },
      });
    } catch (error) {
      console.error(error);

      new Snackbar(error?.toString() ?? "Gửi yêu cầu bị lỗi", {
        position: "top-center",
        style: {
          container: [["background-color", "red"]],
        },
      });
    }
  };

  return (
    <section id="register-form">
      <form onSubmit={_handleSubmit}>
        <div className="d-flex flex-column">
          <div className="Input my-3">
            <input
              type="text"
              id="input"
              className="Input-text"
              placeholder="Nhập họ tên"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
            />
            <label htmlFor="input" className="Input-label">
              Họ và tên
            </label>
          </div>
          <div className="Input my-3">
            <input
              type="email"
              id="input"
              className="Input-text"
              placeholder="Nhập email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="input" className="Input-label">
              Email
            </label>
          </div>
          <div className="Input my-3">
            <input
              type="tel"
              id="input"
              className="Input-text"
              placeholder="Nhập  số điện thoại"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <label htmlFor="input" className="Input-label">
              Số điện thoại
            </label>
          </div>
          <div className="Input my-3">
            <input
              type="text"
              id="input"
              className="Input-text"
              placeholder="Nhập tên công ty"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <label htmlFor="input" className="Input-label">
              Tên công ty
            </label>
          </div>
        </div>

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6Lf5NPIpAAAAAG9wZYY4kjIJrElD9mTBpo1fHUE7"
          style={{
            transform: "scale(0.88)",
            WebkitTransform: "scale(0.88)",
            transformOrigin: "0 0",
            WebkitTransformOrigin: "0 0",
          }}
          onChange={(value) => setCapVal(value)}
        />

        <div className="my-3">
          <button className="btn register-btn" type="submit">
            <div className="d-flex align-items-center">
              {isRegistering ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                <>
                  <span className="me-5">Đăng ký</span>
                  <img
                    src="./img/tms/arrow-right.png"
                    height={24}
                    alt="arrow-right"
                  />
                </>
              )}
            </div>
          </button>
        </div>
      </form>
    </section>
  );
};

export default RegisterFormComponent;

import { BookADemoButton } from "../../../components/book-a-demo/book-a-demo";
import { PageTitle } from "../../../components/page-title/page-title";
import TMSContent from "./content.json";

const HomePageSolutionsComponent = () => {
  const tmsSolutions = TMSContent.tmsSolutions;

  const _cardInfo = ({
    key,
    title,
    imgSrc,
    content,
  }: {
    key: number;
    title: string;
    imgSrc: string;
    content: string | string[];
  }) => {
    return (
      <div key={key} className="col-lg-4 col-md-6">
        <div className="card single-promo-card single-promo-hover h-100">
          <div className="card-body d-flex flex-column align-items-center">
            <img
              src={imgSrc}
              alt="iconSrc"
              className="rounded-3"
              style={{ width: "auto", height: "25vh" }}
            />
            <div className="pt-2 pb-3">
              <h5 className="py-3 text-center border-bottom border-3">
                {title}
              </h5>
              {typeof content === "string" ? (
                <img
                  src={content}
                  alt={title}
                  className="d-block mx-auto mw-100"
                />
              ) : null}
              {Array.isArray(content) ? (
                <ul>
                  {content.map((value, index) => {
                    return (
                      <div
                        key={index}
                        className="d-flex align-items-start mb-2"
                      >
                        <div className="me-3">
                          <img
                            className="icon-img"
                            src="./img/tms/tick-circle.png"
                            alt="tick-circle"
                            width="30rem"
                          />
                        </div>
                        <span>{value}</span>
                      </div>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section id="giai-phap" className="primary-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="d-flex flex-column align-items-center">
              <div className="section-heading text-center mt-md-5 mt-3">
                <PageTitle
                  title="SMARTCARGO TMS Giải quyết vấn đề"
                  className="text-white"
                />
              </div>
              <BookADemoButton className="mt-3" />
            </div>
          </div>
          <div className="row g-4 mb-md-5 mb-3">
            {tmsSolutions.map((item, index) => {
              return _cardInfo({
                key: index,
                title: item.title,
                imgSrc: item.img,
                content: item.content,
              });
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageSolutionsComponent;

import { BiHeadphone } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { BookADemoButton } from "../book-a-demo/book-a-demo";
import TMSContent from "../../pages/home/components/content.json";

const NavBarComponent = () => {
  const navbarItems = TMSContent.navbarItems;
  const location = useLocation();

  if (
    location.pathname.includes("/tin-tuc") ||
    location.pathname.includes("/chinh-sach-bao-mat")
  ) {
    window.scrollTo(0, 0);
    return <_CommonPageNavbar />;
  }

  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src="/img/logo.png"
              width={120}
              alt="Smart Cargo TMS"
              className="img-fluid logo"
            />
          </a>
          <div className="d-flex justify-content-center align-items-center flex-row-reverse">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedHomeContent"
              aria-controls="navbarSupportedHomeContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu" />
            </button>
            <BookADemoButton className="d-lg-none m-0 me-2" />
          </div>
          <div
            className="collapse navbar-collapse main-menu justify-content-end"
            id="navbarSupportedHomeContent"
          >
            <ul className="navbar-nav">
              {navbarItems.map((data, index) => (
                <li key={index} className="nav-item">
                  <a
                    className="nav-link page-scroll"
                    target={data.href}
                    href={data.href}
                  >
                    {data.title}
                  </a>
                </li>
              ))}
              {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link page-scroll dropdown-toggle"
                    href="/blog/"
                    id="navbarBlogPage"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Tin tức &amp; Blog
                    <span className="custom-nav-badge badge text-bg-danger badge-pill">
                      New
                    </span>
                  </a>
                </li> */}
              <li className="nav-item">
                <BookADemoButton className="py-3" />
              </li>
              <a
                href="tel:+84909293228"
                className="btn btn-primary py-0 ms-2 d-flex flex-column"
              >
                <span>
                  <BiHeadphone className="mr-2" /> Hotline hỗ trợ
                </span>
                <span>0909 293 228</span>
              </a>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavBarComponent;

const _CommonPageNavbar = () => {
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src="/img/logo.png"
              width={120}
              alt="Smart Cargo TMS"
              className="img-fluid logo"
            />
          </a>
          <div className="d-flex justify-content-center align-items-center flex-row-reverse">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedHomeContent"
              aria-controls="navbarSupportedHomeContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu" />
            </button>
            <BookADemoButton className="d-lg-none m-0 me-2" />
          </div>
          <div
            className="collapse navbar-collapse main-menu justify-content-end"
            id="navbarSupportedHomeContent"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <BookADemoButton className="py-3" />
              </li>
              <a
                href="tel:+84909293228"
                className="btn btn-primary py-0 ms-2 d-flex flex-column"
              >
                <span>
                  <BiHeadphone className="mr-2" /> Hotline hỗ trợ
                </span>
                <span>0909 293 228</span>
              </a>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

import { BookADemoButton } from "../../../components/book-a-demo/book-a-demo";
import { PageTitle } from "../../../components/page-title/page-title";
import TMSContent from "./content.json";

const HomePageProblemsComponent = () => {
  const tmsProblems = TMSContent.tmsProblems;

  return (
    <section id="van-de">
      <div className="container py-5">
        <div className="d-flex flex-column align-items-center mb-3">
          <div className="col-md-8">
            <div className="section-heading text-center">
              <PageTitle title="Những vấn đề khó khăn trong quản lý vận tải" />
            </div>
          </div>
          <BookADemoButton className="mt-3" />
        </div>
        <div>
          {tmsProblems.map((p, i) => {
            const even = i % 2 === 0;
            const cls = even ? "order-last order-md-first mt-3" : "";
            return (
              <div className="row mb-4 tms-items" key={i}>
                <div
                  className={`col-12 col-md-6 d-flex justify-content-center align-content-center ${cls}`}
                >
                  {even ? (
                    <div className="d-flex flex-column justify-content-center align-content-center mx-3">
                      <h4 className="color-primary text-center">{p.title}</h4>
                      <ul className="list-style-disc">
                        {p.content.map((c, i) => {
                          return <li key={i}>{c}</li>;
                        })}
                      </ul>
                    </div>
                  ) : (
                    <img src={p.img} alt={p.title} className="img-fluid" />
                  )}
                </div>
                <div className="col-12 col-md-6  d-flex justify-content-center align-content-center">
                  {!even ? (
                    <div className="d-flex flex-column justify-content-center align-content-center mx-3">
                      <h4 className="color-primary text-center">{p.title}</h4>
                      <ul className="list-style-disc">
                        {p.content.map((c, i) => {
                          return <li key={i}>{c}</li>;
                        })}
                      </ul>
                    </div>
                  ) : (
                    <img src={p.img} alt={p.title} className="img-fluid" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default HomePageProblemsComponent;

import { RegisterFormComponent } from "..";

const RegisterFormModal = ({
  id,
  title,
  applicationName,
}: {
  id: string;
  title?: string;
  applicationName: string;
}) => {
  return (
    <div
      id={id}
      className="modal register-form-modal fade pe-0"
      tabIndex={-1}
      aria-labelledby="registerFormModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered modal-fullscreen-lg-down">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-uppercase sub-title">
              {title ?? "Đăng ký tư vấn miễn phí và trải nghiệm PyCOM"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div className="row align-items-center">
              <div className="col-md-7 order-md-1 order-2 p-3">
                <h5 className="text-primary">
                  Nhận tư vấn miễn phí từ các Chuyên gia chuyển đổi số có kinh
                  nghiệm của chúng tôi
                </h5>
                <ul>
                  <li style={{ fontSize: ".9rem" }}>
                    &bull; Trải nghiệm Demo trực tiếp để hiểu chi tiết quy trình
                    hệ thống
                  </li>
                  <li style={{ fontSize: ".9rem" }}>
                    &bull; Tư vấn giải pháp phù hợp với mọi nhu cầu của quý công
                    ty
                  </li>
                  <li style={{ fontSize: ".9rem" }}>
                    &bull; Tích hợp vào các phân hệ quản lý khác của doanh
                    nghiệp
                  </li>
                  <li style={{ fontSize: ".9rem" }}>
                    &bull; Giải đáp các câu hỏi, làm rõ thắc mắc của bạn về
                    triển khai, go-live, sử dụng và support
                  </li>
                </ul>
                <h6 className="mt-3 fw-bold">Hotline hỗ trợ</h6>
                <div className="d-flex">
                  <h6 className="fw-bold">
                    <a href="tel:+84909293228">+84 909 293 228</a>
                  </h6>
                  <span className="ms-2">(Mr. Hoàng)</span>
                </div>
                <h6 className="fw-bold mt-2">Email hỗ trợ</h6>
                <h6 className="fw-bold">
                  <a href="mailto:info@pycom.vn">info@pycom.vn</a>
                </h6>
              </div>
              <div className="col-md-5 order-md-2 order-1 px-3">
                <RegisterFormComponent
                  applicationName={applicationName}
                  onRegisteredSuccess={() => {
                    document.body.removeAttribute("style");
                    document.body.classList.remove("modal-open");

                    var modal = document.getElementById("registerFormModal");

                    if (modal) {
                      modal.classList.remove("show");
                      modal.removeAttribute("style");
                      modal.removeAttribute("aria-modal");
                      modal.removeAttribute("role");
                      modal.setAttribute("aria-hidden", "true");
                      modal.style.display = "none";
                      document.querySelector(".modal-backdrop")?.remove();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterFormModal;

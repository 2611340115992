import { useState } from "react";
import { RegisterFormModal } from "../../../components";
import { BookADemoButton } from "../../../components/book-a-demo/book-a-demo";
import { PageTitle } from "../../../components/page-title/page-title";
import TMSContent from "./content.json";

const HomePagePricePlanComponent = () => {
  const smartCargoTmsPackages = TMSContent.smartCargoTmsPackages;
  const zaloMiniAppPackages = TMSContent.zaloMiniAppPackages;
  const [packageNameRequest, setRegisteredPackage] = useState("");

  const _renderPackgeNameInfo = ({
    key,
    name,
    textList,
  }: {
    key: number;
    name: string;
    textList: string[];
  }) => {
    return (
      <div key={key}>
        <span className="text-white fw-bold">{name}</span>
        {textList.length > 0 &&
          textList.map((value, index) => {
            return (
              <div key={index} className="text-white">
                <span>- {value}</span>
              </div>
            );
          })}
      </div>
    );
  };

  const _renderPackgePriceInfo = ({
    key,
    packageName,
    basicPrice,
    advancedPrice,
  }: {
    key: number;
    packageName: string;
    basicPrice: string;
    advancedPrice: string;
  }) => {
    return (
      <div key={key} className="h-100">
        <div className="row py-3 d-lg-none d-flex text-white fw-bold border-top border-bottom border-light-subtle">
          <div className="col p-0 d-flex justify-content-center align-items-center text-center">
            Gói cơ bản
          </div>
          <div className="col p-0 d-flex justify-content-center align-items-center text-center">
            Gói nâng cao
          </div>
          <div className="col p-0 d-flex justify-content-center align-items-center text-center">
            Gói theo yêu cầu
          </div>
        </div>
        <div className="row text-white fw-bold">
          <div className="col p-0 d-flex flex-column justify-content-between position-relative">
            <img
              src="./img/tms/sale-20.png"
              width={50}
              alt="sale-20%"
              className="position-absolute top-0 end-0"
            />
            <div className="d-flex flex-column justify-content-center align-items-center text-center mt-3">
              <span className="fs-5">{basicPrice}</span>
              <span>VNĐ/Tháng</span>
              {_renderRegisterPackageButton({
                packageName: `Đăng ký Smart Cargo TMS ${packageName} cơ bản (${basicPrice}đ/Tháng)`,
              })}
            </div>
          </div>
          <div className="col p-0 d-flex flex-column justify-content-between position-relative">
            <img
              src="./img/tms/sale-20.png"
              width={50}
              alt="sale-20%"
              className="position-absolute top-0 end-0"
            />
            <div className="d-flex flex-column justify-content-center align-items-center text-center mt-3">
              <span className="fs-5">{advancedPrice}</span>
              <span>VNĐ/Tháng</span>
              {_renderRegisterPackageButton({
                packageName: `Đăng ký Smart Cargo TMS ${packageName} nâng cao (${advancedPrice}đ/Tháng)`,
              })}
            </div>
          </div>
          <div className="col p-0 d-flex flex-column justify-content-between position-relative">
            <img
              src="./img/tms/sale-20.png"
              width={50}
              alt="sale-20%"
              className="position-absolute top-0 end-0"
            />
            <div className="d-flex flex-column justify-content-center align-items-center text-center mt-3">
              <span className="fs-5 invisible">A</span>
              <span>Theo yêu cầu</span>
              {_renderRegisterPackageButton({
                packageName: `Đăng ký Smart Cargo TMS ${packageName} theo yêu cầu`,
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderRegisterPackageButton = ({
    packageName,
  }: {
    packageName: string;
  }) => {
    return (
      <button
        type="button"
        className="btn btn-light mx-lg-4 my-lg-2 m-2 px-lg-4 py-lg-1 px-2 py-1"
        data-bs-toggle="modal"
        data-bs-target="#registerPackageFormModal"
        onClick={() => setRegisteredPackage(packageName)}
      >
        Đăng ký
      </button>
    );
  };

  return (
    <section id="bang-gia" className="primary-bg">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="d-flex flex-column align-items-center">
              <div className="section-heading text-center">
                <PageTitle title="Bảng giá" className="text-white" />
              </div>
              <h5 className="text-white mt-3 mb-0">
                Giá cước dịch vụ và thời gian sử dụng (Tối thiểu 1 năm)
              </h5>
              <span className="text-white mt-1 fst-italic">
                Trải nghiệm miễn phí 1 tháng
              </span>
              <BookADemoButton className="mt-3" />
            </div>
          </div>
          <div className="row mt-3 d-lg-flex justify-content-lg-center d-none primary-bg-blur text-white fw-bold">
            <div className="col-xl-4 col-lg-6 col-12 p-0">
              <div className="d-flex justify-content-center align-items-center text-center p-3">
                Loại Gói dịch vụ
              </div>
            </div>
            <div className="col-lg-6 col-12 p-0">
              <div className="row">
                <div className="col p-0">
                  <div className="d-flex justify-content-center align-items-center text-center p-3">
                    Gói cơ bản
                  </div>
                </div>
                <div className="col p-0">
                  <div className="d-flex justify-content-center align-items-center text-center p-3">
                    Gói nâng cao
                  </div>
                </div>
                <div className="col p-0">
                  <div className="d-flex justify-content-center align-items-center text-center p-3">
                    Gói theo yêu cầu
                  </div>
                </div>
              </div>
            </div>
          </div>

          {smartCargoTmsPackages.map((item, index) => {
            return (
              <div
                key={index}
                className={`row p-0 d-lg-flex justify-content-lg-center ${
                  index % 2 !== 0 ? "primary-bg-blur" : ""
                }`}
              >
                <div className="col-xl-4 col-lg-6 col-12 p-3 border-end border-light-subtle">
                  {_renderPackgeNameInfo({
                    key: index,
                    name: item.name,
                    textList: item.content,
                  })}
                </div>
                <div className="col-lg-6 col-12">
                  {_renderPackgePriceInfo({
                    key: index,
                    packageName: item.name,
                    basicPrice: item.basicPrice,
                    advancedPrice: item.advancedPrice,
                  })}
                </div>
              </div>
            );
          })}

          <div
            className={`row p-0 d-lg-flex justify-content-lg-center text-white ${
              smartCargoTmsPackages.length % 2 !== 0 ? "primary-bg-blur" : ""
            }`}
          >
            <div className="col-xl-4 col-lg-6 col-12 p-lg-3 pb-0 border-end border-light-subtle">
              <span className="text-white fw-bold">
                Thời gian đăng ký sử dụng tối thiểu
              </span>
            </div>
            <div className="col-lg-6 col-12">
              <div className="row py-3 d-flex fw-bold">
                {Array.from({ length: 3 }, (_, index) => (
                  <div
                    key={index}
                    className="col p-0 d-flex justify-content-center align-items-center text-center"
                  >
                    1 năm
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="d-flex flex-column align-items-center">
              <div className="section-heading text-center mt-md-5 mt-3">
                <PageTitle title="Zalo Mini App" className="text-white" />
              </div>
            </div>
          </div>
          <div className="row mt-3 d-lg-flex justify-content-lg-center d-none primary-bg-blur text-white fw-bold">
            <div className="col-xl-4 col-lg-6 col-12 p-0">
              <div className="d-flex justify-content-center align-items-center text-center p-3">
                Loại Gói dịch vụ
              </div>
            </div>
            <div className="col-lg-6 col-12 p-0">
              <div className="row">
                <div className="col p-0">
                  <div className="d-flex justify-content-center align-items-center text-center p-3">
                    Gói cơ bản
                  </div>
                </div>
                <div className="col p-0">
                  <div className="d-flex justify-content-center align-items-center text-center p-3">
                    Gói nâng cao
                  </div>
                </div>
                <div className="col p-0">
                  <div className="d-flex justify-content-center align-items-center text-center p-3">
                    Gói theo yêu cầu
                  </div>
                </div>
              </div>
            </div>
          </div>

          {zaloMiniAppPackages.map((item, index) => {
            return (
              <div
                key={index}
                className={`row p-0 d-lg-flex justify-content-lg-center ${
                  index % 2 !== 0 ? "primary-bg-blur" : ""
                }`}
              >
                <div className="col-xl-4 col-lg-6 col-12 p-3 border-end border-light-subtle">
                  {_renderPackgeNameInfo({
                    key: index,
                    name: item.name,
                    textList: item.content,
                  })}
                </div>
                <div className="col-lg-6 col-12">
                  {_renderPackgePriceInfo({
                    key: index,
                    packageName: item.name,
                    basicPrice: item.basicPrice,
                    advancedPrice: item.advancedPrice,
                  })}
                </div>
              </div>
            );
          })}
        </div>

        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="d-flex flex-column align-items-center">
              <div className="section-heading text-center mt-md-5 mt-3">
                <PageTitle title="Quyền lợi" className="text-white" />
              </div>
            </div>
          </div>

          <div
            className={`row mt-3 p-0 d-lg-flex justify-content-lg-center text-white primary-bg-blur`}
          >
            <div className="col-xl-4 col-lg-6 col-12 p-3 border-end border-light-subtle">
              <span className="text-white fw-bold">
                Hỗ trợ setup dữ liệu ban đầu (miễn phí)
              </span>
            </div>
            <div className="col-lg-6 col-12">
              <div className="row py-3 d-flex fw-bold">
                {Array.from({ length: 3 }, (_, index) => (
                  <div
                    key={index}
                    className="col p-0 d-flex justify-content-center align-items-center text-center"
                  >
                    Có
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <RegisterFormModal
        id="registerPackageFormModal"
        title={packageNameRequest}
        applicationName={packageNameRequest}
      />
    </section>
  );
};

export default HomePagePricePlanComponent;

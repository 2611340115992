import TMSContent from "../../pages/home/components/content.json";

const FooterComponent = () => {
  const navbarItems = TMSContent.navbarItems;

  return (
    <footer className="footer-section">
      <div className="footer-top py-5 background-img">
        <div className="container mt-4">
          <div className="row justify-content-between">
            <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
              <div className="footer-nav-wrap text-white">
                <h5 className="mb-2 text-white">
                  Công Ty TNHH Công nghệ PYCOM SOLUTIONS
                </h5>
                <ul className="d-flex flex-column mb-2">
                  <span className="mb-2" style={{ fontSize: 14 }}>
                    Địa chỉ: Villa D6, Village An Phú Đông, An Phú Đông, Quận
                    12, Hồ Chí Minh
                  </span>
                  <span className="mb-2" style={{ fontSize: 14 }}>
                    Chi nhánh: 126 Hà Huy Giáp, KDC An Lộc, Thạnh Lộc, Quận 12,
                    Hồ Chí Minh
                  </span>
                  <span style={{ fontSize: 14 }}>MST: 0317800291</span>
                </ul>
                <p className="text-white" style={{ fontSize: 14 }}>
                  © Powered By PyCOM Solutions.
                  <br /> All rights reserved.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-8">
              <div className="row">
                <div className="col-6 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Danh mục</h5>
                    <ul className="list-unstyled">
                      {navbarItems.map((data, index) => (
                        <li key={index} className="mb-2">
                          <a
                            className="page-scroll"
                            target={data.href}
                            href={data.href}
                          >
                            {data.title}
                          </a>
                        </li>
                      ))}

                      <li className="mb-2">
                        <a
                          href="/chinh-sach-bao-mat"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Chính sách bảo mật
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-6 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Về PyCOM</h5>
                    <ul className="list-unstyled support-list">
                      <li className="mb-2">
                        <a
                          href="https://pycom.vn/giai-phap-van-tai-tms"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Quản lý Vận Tải (TMS)
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          href="https://pycom.vn/giai-phap-phan-phoi-dms"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Quản lý Phân Phối (DMS)
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          href="https://pycom.vn/giai-phap-zalo-apps"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Zalo Mini Apps Service
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="footer-nav-wrap text-white footer-form">
                    <h5 className="mb-3 text-white">Hẹn lịch demo</h5>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nhập email của bạn"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={handleKeyDown}
                      />
                      <div
                        className="input-group-text pe-3"
                        onClick={isRegistering ? undefined : _onRegistered}
                      >
                        <IconSend />
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;

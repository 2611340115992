import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { MwaDateTimeUtil, SessionState } from "../../utils";
import { BlogService } from "../../services";
import { BlogModel } from "../../models";
import Skeleton from "react-loading-skeleton";
import { MwaImage } from "../../components";
import { useRecoilValue } from "recoil";
import { blogsState } from "../../states";
import "./blog-detail.css";

const BlogDetailPage = () => {
  const { id } = useParams();
  const [blogDetail, setBlogDetail] = useState<BlogModel>();
  const [loadingBlogDetail, setLoadingBlogDetail] = useState(SessionState.idle);

  const blogList = useRecoilValue(blogsState);

  useEffect(() => {
    fetchBlogDetail();
  }, []);

  const fetchBlogDetail = async () => {
    setLoadingBlogDetail(SessionState.loading);

    const response = await BlogService.fetchBlogById(id as string);

    if (response.isSuccess) {
      setLoadingBlogDetail(SessionState.success);
    } else {
      setLoadingBlogDetail(SessionState.error);
    }
    setBlogDetail(response);
  };

  const _renderContent = () => {
    switch (loadingBlogDetail) {
      case SessionState.success:
        return blogDetail ? (
          <>
            <ol className="breadcrumb">
              <li className="">
                <Link to="/tin-tuc">Tất cả</Link>
              </li>
              <li className="mx-2">&gt;</li>
              <li className="breadcrumb-item active">Chi tiết</li>
            </ol>
            <div className="row">
              <div className="col-lg-8">
                <h3 className="m-0">{blogDetail.name}</h3>
                {blogDetail.createdAt && (
                  <span style={{ fontSize: ".8rem" }}>
                    {MwaDateTimeUtil.historyAgo(blogDetail.createdAt)}
                  </span>
                )}
                <p>{blogDetail.summary}</p>
                <div
                  dangerouslySetInnerHTML={{ __html: blogDetail.content ?? "" }}
                />
              </div>
              <div className="col-lg-4 sticky-top">
                <div className="row g-4">
                  {blogList.items
                    .filter((e) => e.id !== blogDetail.id)
                    .slice(0, 4)
                    .map((blogInfo, index) => {
                      return (
                        <div key={index} className="col-12">
                          <Link
                            to={`/tin-tuc/${blogInfo.id}`}
                            target="_blank"
                            className="text-black"
                          >
                            <div className="d-flex">
                              <div
                                style={{
                                  height: "8vw",
                                  width: "8vw",
                                }}
                              >
                                <MwaImage
                                  src={blogInfo.thumbnail}
                                  alt="thumbnail-img"
                                  style={{
                                    height: "inherit",
                                    width: "inherit",
                                  }}
                                  fallbackSrc="/img/logo.png"
                                />
                              </div>
                              <div className="d-flex flex-column ms-2">
                                <span
                                  className="news-title"
                                  style={{ fontSize: ".9rem" }}
                                >
                                  {blogInfo.name}
                                </span>
                                {blogInfo.createdAt && (
                                  <span style={{ fontSize: ".8rem" }}>
                                    {MwaDateTimeUtil.historyAgo(
                                      blogInfo.createdAt
                                    )}
                                  </span>
                                )}
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        );

      case SessionState.loading:
        return (
          <div className="container d-flex flex-column justify-content-center">
            <div className="row">
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index} className="col-lg-6 mb-4">
                  <Skeleton height="40vh" />
                </div>
              ))}
            </div>
          </div>
        );

      case SessionState.error:
        return (
          <div className="d-flex justify-content-center py-3">
            <button
              className="btn btn-danger d-flex flex-column justify-content-center align-items-center"
              onClick={fetchBlogDetail}
            >
              <span>
                Lỗi tải chi tiết tin tức: {blogDetail?.error?.message}
              </span>
              <span> Thử lại</span>
            </button>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div id="blog-detail-page" className="main pt-4">
      <div className="container">{_renderContent()}</div>
    </div>
  );
};

export default BlogDetailPage;
